<template>
  <v-form ref="form" class="mt-4" v-model="valid" @submit.prevent="send">
    <v-alert v-if="success" type="success">
      Your message has been sent
    </v-alert>
    <input type="submit" hidden />
    <div>
      <v-radio-group v-model="formData.type">
        <div class="d-flex flex-wrap">
          <span class="mr-2 text-h6 label--text" style="margin-top: -5px">
            I have a
          </span>
          <v-radio
            v-for="(type, index) in CONTACT_TYPES"
            :key="index"
            :label="type.text"
            :value="type.value"
            class="mr-5"
          ></v-radio>
        </div>
      </v-radio-group>
    </div>
    <v-textarea
      :rules="rules.requiredRule"
      outlined
      rows="5"
      auto-grow
      v-model="formData.message"
      label="Please explain..."
    ></v-textarea>

    <v-radio-group
      :rules="rules.questionRule"
      class="reply"
      v-model="formData.reply"
    >
      <div class="d-flex">
        <v-radio class="mt-2" label="No reply needed" :value="false"></v-radio>
        <v-radio class="ml-5" label="Please reply" :value="true"></v-radio>
      </div>
    </v-radio-group>
    <div>
      <v-btn
        :loading="loading"
        @click="send"
        large
        width="150"
        class="text-capitalize"
        color="primary"
        >Submit <v-icon>mdi-send</v-icon></v-btn
      >
      <v-btn @click="reset()" large color="secondary" text>Cancel </v-btn>
    </div>
    <!-- <p class="my-4 title ml-9">OR</p>
    <div>
      <v-btn
        width="150"
        class="text-capitalize"
        href="mailto:peepstruro@gmail.com"
        target="_blank"
        large
        dark
        color="cyan"
        >Email Peeps <v-icon>mdi-email</v-icon></v-btn
      >
    </div> -->
  </v-form>
</template>

<script>
import rules from '@/mixins/rules'
import contact from '@/mixins/contact'
import selection from '../mixins/selection'
import { mapGetters } from 'vuex'
export default {
  mixins: [rules, contact, selection],
  data: () => ({
    formData: {},
    defaultFormData: {
      reply: false,
      type: '',
      message: ''
    },
    valid: false,
    success: false,
    loading: false
  }),
  computed: {
    ...mapGetters({
      CONTACT_TYPES: 'selection/CONTACT_TYPES'
    })
  },
  beforeMount() {
    this.setDefaultData()
  },
  mounted() {
    this.checkType()
  },
  methods: {
    setDefaultData() {
      this.formData = this.copyObject(this.defaultFormData)
    },
    async send() {
      this.$refs.form.validate()
      if (this.valid) {
        this.loading = true

        try {
          await this.sendMessage(this.formData)
        } catch (err) {
          console.log(err)
        }

        this.success = true
        this.loading = false
        this.reset()
        setTimeout(() => {
          this.success = false
        }, 5000)
      }
    },
    reset() {
      this.setDefaultData()
      this.$refs.form.resetValidation()
    },
    checkType() {
      let type = this.$route.query.type
      type = this.getContactType(type)
      if (!type) {
        return
      }
      this.defaultFormData.type = type.value
      if (type.value == 'helpRequest') {
        this.defaultFormData.reply = true
      }
      this.setDefaultData()
    }
  }
}
</script>

<style scoped>
.reply {
  margin-top: -15px !important;
}
</style>
